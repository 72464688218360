import createApp from "./main.black";
import Worker from 'worker-loader!./web-worker'
import environment from './env'

const app = createApp(environment, () => {
    const worker = new Worker();
    worker.postMessage({ type: 'env-init', env: environment });
    return worker;
});

app.mount("#app");